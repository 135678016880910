import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider ,getToken} from "firebase/app-check";
import { getFunctions,connectFunctionsEmulator} from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBVD7__S8tFmYBHcA2R47-ONtxfMGLDzbs",
    authDomain: "taccoin.firebaseapp.com",
    projectId: "taccoin",
    storageBucket: "taccoin.appspot.com",
    messagingSenderId: "609150439089",
    appId: "1:609150439089:web:ac7b7b3b392a83c41c5c90",
    measurementId: "G-5YFNF35T96"
  };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const storage = getStorage(app, "gs://taccoin.appspot.com");
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);
//window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ld3-q0pAAAAANj_Di8G-xmS4VEyFDWrFbN46Ex9'),
  isTokenAutoRefreshEnabled: true
});  
export const getTokenAppCheck=async()=>{
  let appCheckTokenResponse;
  try {
      appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
  } catch (err) {
      // Handle any errors if the token was not retrieved.
      return;
  }
  return appCheckTokenResponse.token;
}
