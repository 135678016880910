import React, { useState,useContext} from "react";
import { AppContext } from "../App";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider,sendPasswordResetEmail } from "firebase/auth";
import { auth,functions,getTokenAppCheck } from "../firebaseconfig";
import { httpsCallable} from "firebase/functions";

import { useNavigate } from 'react-router-dom';


import { addUser,deleteUser} from "../db";
import CryptoJS from 'crypto-js';
const Login = () => {
  
  const { state,dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nickname, setNickName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isForgot, setIsForgot] = useState(false); 
  
  const [isLogin, setIsLogin] = useState(true); 
  const storeUser=async(email,password)=>{
    const { v4: uuidv4 } = require('uuid');
    const uid = uuidv4();
    const key= uid + password + 'TossACoin';
    const hash = CryptoJS.AES.encrypt(email, key).toString();
    addUser(uid,hash);

  }
  const getDetails=async()=>{
    
    const sForm = httpsCallable(functions, 'getDetails',
        { 'X-Firebase-AppCheck': getTokenAppCheck() });
       let result="";
        try{ 
            result = await sForm([{ email: email}]);
            return result.data;
        }catch(err){
          console.log(err);
          return false;
        }
  
    }
  const addUserToFireBase=async(email,nickname)=>{
  //Firebase function
  
  
  const sForm = httpsCallable(functions, 'addUser',
      { 'X-Firebase-AppCheck': getTokenAppCheck() });
      
      sForm([{ email: email,nickname:nickname}])
        .then((result) => {
          return true;
          
        }).catch((error) => {
        
          const message = error.message;
          console.log("Error:",message);
          return false;
          });
 

  }
  const sendResetEmail=async(email)=>{
    const auth = auth;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return false;
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
          navigate("/buytac");  
       
      } catch (err) {
        await deleteUser();
        setError("Failed to log in");
      }
    } else {
      try {
        if(nickname.length<3){
          setError("Nickname must be at least 3 characters");
          return;
        }
        if (password !== confirmPassword) {
          setError("Passwords do not match");
          return; 
        }
        let firebaseResult=await createUserWithEmailAndPassword(auth, email, password);
        console.log('firebaseResult:',firebaseResult);
        
        await storeUser(email,password);
        await addUserToFireBase(email,nickname);
        navigate("/buytac");
      } catch (err) {
        await deleteUser();
        setError("Failed to sign up");
      }
    }
  };

  
  const handleFocus = () => {
    setError("");
  };
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6} className="text-center" style={{
          width: "90%",
          maxWidth: 600,
          marginTop: 50,
          padding: 25,
          border: "1px solid rgb(222, 226, 230)",
        }}>
          <img className="logo-light" src="/logo2x.png" alt="logo" />
          <h3 style={{ marginTop: 20 }}>Welcome to Decentralized Influencer</h3>
  
          <h2 className="text-center">{isLogin ? "Login" : "Sign Up"}</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            {!isLogin && (
              <Form.Group controlId="formNickName" className="mt-3">
                <Form.Label>Nickname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your Nickname"
                  value={nickname}
                  onFocus={handleFocus}
                  onChange={(e) => setNickName(e.target.value)}
                  required
                />
              </Form.Group>
            )}
  
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onFocus={handleFocus}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
  
            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onFocus={handleFocus}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            {!isLogin && (
              <Form.Group controlId="formConfirmPassword" className="mt-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onFocus={handleFocus}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
            )}
            <Button variant="primary" type="submit" className="mt-4" block>
              {isLogin ? "Login" : "Sign Up"}
            </Button>
          </Form>
  
          <hr />
  
          <div className="text-center mt-3">
            <Button
              variant="link"
              onClick={() => setIsLogin(!isLogin)}
              className="text-primary"
            >
              {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Login"}
            </Button>
            {/* Forgot Password Butonu */}
            <div className="mt-3">
              <Button
                variant="link"
                onClick={() => setIsForgot(true)} // isForgot durumunu true yap
                className="text-primary"
              >
                Forgot Password?
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}  

export default Login;